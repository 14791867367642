import React from "react"
import * as Icon from "react-feather"
const buyerNavigationConfig = [
  /* {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/dashboard"

  }, */

  {
    id: "Self Disclosure",
    title: "Self Disclosure",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/registration-form"
  },
  {
    id: "Document Manager",
    title: "Document Manager",
    type: "item",
    icon: <Icon.Upload size={20} />,
    navLink: "/file-upload"
 
  },
 
]

export default buyerNavigationConfig
