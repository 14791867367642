import React from "react"
import * as Icon from "react-feather"
const adminNavigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/admin-dashboard"
 
  },
  /* {
    id: "verificaion",
    title: "Verification",
    type: "item",
    icon: <Icon.List size={20} />,
    navLink: "/banker-verification-list"
 
  }, */
 

 
]

export default adminNavigationConfig