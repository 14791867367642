import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"
import { reducer as reduxFormReducer } from 'redux-form';

const middlewares = [thunk, createDebounce()]
const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares)),
  (window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore)(reducer)
  
)

export { store }
