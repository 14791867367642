import React , { useState ,useEffect} from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Navbar
} from "reactstrap"
import { Bell,AlertTriangle ,PlusSquare,DownloadCloud,CheckCircle,File} from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import ReactCountryFlag from "react-country-flag"
import axios from "axios"
import Select from "react-select"
//import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
//import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {
  logoutWithJWT
} from "../../../redux/actions/auth/loginActions"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/images.jpeg"
import { auth } from "firebase"
import { FormattedMessage } from "react-intl"
import { toast, ToastContainer } from "react-toastify"
const nationalityOptions = [
 
  {
    value:  <FormattedMessage id={"English"} /> ,
    label:  <FormattedMessage id={"English"} /> 
  },
  {
    value: <FormattedMessage id={"German"} /> ,
    label:  <FormattedMessage id={"German"} /> 
  }  
] 
const UserName = props => {
  var auth= JSON.parse(localStorage.getItem("UserDetails"))
  let username
  if(auth && auth.userType && auth.userName){
    username = (auth.userName).toUpperCase()
  
 /*  }
  let username = ""
  if (props.userdata !== undefined) {
    username = props.userdata.name
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name
    } */
  } else {
    username = "John Doe"
  }

  return username
}
const ThemeNavbar = props => {
  const [selectedApplicantNationalityOption,setSelectApplicantNationalityOption] =useState({
    applicantNationalityOption:{}
  })
 // const { user } = useAuth0()
  const [modal,setModel]=useState(false)
  const [modal1,setModel1]=useState(false)
  const [data,setData]=useState()
  const[messageArr,setMessageArr]=useState([])
  const[message,setMessage]=useState({
    testMessage:""
  })
  const auth= JSON.parse(localStorage.getItem("UserDetails"))
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  const handleApplicantNationalityChange = selectedOption => {
    setSelectApplicantNationalityOption({applicantNationalityOption: selectedOption}); 
    var userData = {};
    userData.token = auth.token
    userData.user_id = auth.user_id;
    userData.expires_in = auth.expires_in
    userData.userType = auth.userType;
    userData.userName = auth.userName;
    userData.phone = auth.phone;
    userData.loginWithJWT ="jwt";
    if(selectedOption.value.props.id =="English"){
      userData.language = "English"
    }
    if(selectedOption.value.props.id =="German"){
      userData.language = "German"
    }
    //userData.language = selectedOption.value
    userData.profileImage = auth.profileImage ?  auth.profileImage : ""
  
    var data = JSON.stringify(userData);
    localStorage.setItem("UserDetails", data);
    window.location.reload(true); 
  };
  useEffect(() => {
    var token = auth.token
  //var auth= JSON.parse(localStorage.getItem("UserDetails"))
    setSelectApplicantNationalityOption({applicantNationalityOption: {value:  <FormattedMessage id={auth.language} />,label:  <FormattedMessage id={auth.language} />}}); 

 
   }, []);

 
  const toggleModal = (e) => {
    var data = messageArr[e]
    setModel(prevState => (
    !prevState
    ))
    setData(data)
  }

  const toggleModal1 = (e) => {
 
    if(modal1 == true){
      setModel1(false)
     
    }else{
      setModel1(true)
     // setData(data)
    }
  }

  const  handleInputData= (evt)=> {
    const {name,value } = evt.target;
    setMessage(prevState => ({
      ...prevState,
      [name]: value
  })); 

}
const sendMessage = () =>{
  if(data){
  var token = auth.token 
  var obj={}
  obj.msg = message.testMessage
  obj.receiverUserId = [data.senderUserId]
  obj.orderNo = data.orderNo
  
  axios
  .post("/v1/api/message/"+ token +"/send",obj)
    .then(function(response) {
      console.log("fetched all user form",response.data.data)
      toast.success("Message send successfully");
      setModel1(false)
      setModel(false)
      setMessage({  testMessage:""})
    })
    .catch(function(err){
      toast.error("Internal server error");
      console.log("error from server",err)
      setModel(false)
    })
  }

}

  return (
    <div className="container">
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper" style={{width:"100%"}}>
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
                {auth.userType === "buyer"?
               <h3 style={{marginLeft:"1%",marginTop:"1.5%",width:"100%"}}> <FormattedMessage id={"Welcome to YourFinance"} /> </h3>
               :null }
               {auth.userType === "banker"?
               <h3 style={{marginLeft:"10%",marginTop:"1.5%",width:"100%"}}> <FormattedMessage id={"Welcome to YourFinance"} /></h3>
               :null }
                     {auth.userType === "admin"?
               <h3 style={{marginLeft:"1%",marginTop:"1.5%",width:"100%"}}> <FormattedMessage id={"Welcome to YourFinance"} /> </h3>
               :null }
                
              </div>
              <div style={{width:"20%"}}>
            
            <Select  style={{width:"5%"}}
               className="React-Select"
               classNamePrefix="select"
              value= {selectedApplicantNationalityOption.applicantNationalityOption} 
               onChange={handleApplicantNationalityChange}
               name="select"
               isSearchable={true}
               required
             
               options={nationalityOptions}
              
            />
          </div>
          

          <ul className="nav navbar-nav float-right">
              {/*             <UncontrolledDropdown
                            tag="li"
                            className="dropdown-language nav-item"
                          >
                            <DropdownToggle
                              tag="a"
                              data-toggle="dropdown"
                              className="nav-link"
                              //onChange={handleApplicantNationalityChange}
                            >
                              <ReactCountryFlag code="us" svg /> English
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem tag="a">
                                <ReactCountryFlag code="us" svg /> English
                              </DropdownItem>
                              <DropdownItem tag="a">
                                <ReactCountryFlag code="fr" svg /> French
                              </DropdownItem>
                              <DropdownItem tag="a">
                                <ReactCountryFlag code="de" svg /> German
                              </DropdownItem>
                              <DropdownItem tag="a">
                                <ReactCountryFlag code="pt" svg /> Portuguese
                              </DropdownItem>
                            </DropdownMenu>
                            


                          </UncontrolledDropdown> */}
                          <UncontrolledDropdown
                            className="dropdown-notification nav-item"
                            tag="li"
                          >
                            <DropdownToggle
                              tag="a"
                              data-toggle="dropdown"
                            //  aria-expanded={this.state.dropdownNotification}
                              className="nav-link nav-link-label"
                            >
                              <Bell size={21} />
                              <Badge pill color="primary" className="badge-up">
                                {messageArr.length}
                                {" "}
                              </Badge>
                            </DropdownToggle>
                            <DropdownMenu
                              tag="ul"
                              right
                              className="dropdown-menu-media"
                            >
                              <li className="dropdown-menu-header">
                                <div className="dropdown-header mt-0">
                                  <h3 className="text-white"></h3>
                                  <span className="notification-title">
                                    Notifications
                                  </span>
                                </div>
                              </li>
                              <PerfectScrollbar
                                className="media-list overflow-hidden position-relative"
                                options={{
                                  wheelPropagation: false
                                }}
                              >
                          
                          { messageArr.length>0  && messageArr.map((item,index) =>
                                <div className="d-flex justify-content-between">
                                  <Media className="d-flex align-items-start">
                                    <Media left href="#">
                                      <CheckCircle
                                        className="font-medium-5 info"
                                        size={21}
                                        
                                      />
                                    </Media>
                                    <Media body>
                                      <Media
                                        heading
                                        className="info media-heading"
                                        tag="h6"
                                      >
                                        {/* Complete the task onClick={toggleModal} */}
                                      </Media>
                                      <small  onClick={() => toggleModal(index)}  id= {index} className="notification-text" style=  {  {display: 'block',whiteSpace: 'nowrap',width: '25em',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                                        {item.msg}
                                      </small>
                                    
                                    </Media>
                                    <small>
                                      <time
                                        className="media-meta"
                                        dateTime="2015-06-11T18:29:20+08:00"
                                      >
                                       {/*  Last week */}
                                      </time>
                                    </small>
                                  </Media>
                                </div>
                          )}
                                
                          
                              </PerfectScrollbar>
  {/*                             <Modal
        isOpen={modal == true  ? true : false}
        toggle={toggleModal} 
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={onCloseModal}>
         
        </ModalHeader>
        <ModalBody>
      


        
          <FormGroup>

          <Label for="firstName"><b style={{fontSize :"15px"}}>Message</b><span><b style={{fontSize :"15px",marginLeft:"300px"}}>{data ? data.orderNo :""}</b></span> </Label>
            <textarea
              type="text"
              id="msg"
              value= {data ? data.msg :""}
              placeholder=""
              name="message"
              //onChange={handleInputData} 
              style={{width:"100%",height:"250px"}}
          disabled
            />
          </FormGroup>
         

          <FormGroup>
          <Button color="primary"onClick={toggleModal1} >
            Reply
          </Button>{" "}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCloseModal}>
            close
          </Button>{" "}
        </ModalFooter>
      </Modal> */}


      <Modal
                  isOpen={modal}
                  toggle={toggleModal}
                  className="modal-dialog-centered"
                  fade={false}
                >
                  <ModalHeader toggle={toggleModal}>
                  {" "}
                  </ModalHeader>
                  <ModalBody>
                   
                  <FormGroup>

<Label for="firstName"><b style={{fontSize :"15px"}}>Message</b><span><b style={{fontSize :"15px",marginLeft:"300px"}}>{data ? data.orderNo :""}</b></span> </Label>
  <textarea
    type="text"
    id="msg"
    value= {data ? data.msg :""}
    placeholder=""
    name="message"
    //onChange={handleInputData} 
    style={{width:"100%",height:"250px"}}
disabled
  />
</FormGroup>
{/* <FormGroup>
          <Button color="primary"onClick={toggleModal1} >
            Reply
          </Button>{" "}
          </FormGroup> */}
                  </ModalBody>
                  <ModalFooter>
                 {/*    <Button color="primary" onClick={toggleModal}>
                      close
                    </Button>{" "} */}
                      <Button color="primary"onClick={toggleModal1} >
            Reply
          </Button>{" "}
                  </ModalFooter>
                </Modal>

     
   
   
         
                            </DropdownMenu>
                            <Modal
        isOpen={modal1}
        toggle={toggleModal1}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal1}>
         {/*  Profile */}
        </ModalHeader>
        <ModalBody>
      


        
          <FormGroup>

          <Label for="firstName"><b style={{fontSize :"15px"}}>Message</b></Label>
            <textarea
              type="text"
              id="testMessage"
              name='testMessage'
             value= {message.testMessage}
              placeholder=""
          
            onChange={handleInputData} 
              style={{width:"100%",height:"250px"}}
          
            />
          </FormGroup>
         

        {/*   <FormGroup>
          <Button color="primary"  onClick={sendMessage}>
            Send
          </Button>{" "}
          </FormGroup> */}
        </ModalBody>
        <ModalFooter>
        <Button color="primary"  onClick={sendMessage}>
            Send
          </Button>{" "}
        {/*   <Button color="primary" onClick={toggleModal1}>
            close
          </Button>{" "} */}
        </ModalFooter>
      </Modal>
                          </UncontrolledDropdown>
                          </ul> 
        
             {/*  {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null} */}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName/>}
                 userImg={
                 /*  props.user.login.values !== undefined &&
                  props.user.login.values.loggedInWith !== "jwt" &&
                  props.user.login.values.photoUrl
                    ? props.user.login.values.photoUrl 
                    : user !== undefined && user.picture ? user.picture
                    : */ 
                    auth.profileImage ?  auth.profileImage :    userImg
                } 
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
              
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
})(ThemeNavbar)
