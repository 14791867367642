import * as firebase from "firebase/app"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"
import axios from "axios"


var setLocalStorageDetailsAtRegisterTime = userDetails => {
  var userData = {};
  userData.token = userDetails.authToken
  userData.user_id = userDetails._id;
  userData.expires_in = userDetails.authToken.expiresIn
  userData.userType = userDetails.userType;
  userData.userName = userDetails.userName;
  userData.phone = userDetails.phone;
  userData.loginWithJWT ="jwt";
  userData.language = userDetails.language
  userData.profileImage = userDetails.profileImage ?  userDetails.profileImage : ""

  var data = JSON.stringify(userData);
  localStorage.setItem("UserDetails", data);
  return data; 
};
export const setCurrentUser = decoded => {
  // console.log("decoded", decoded);
  return {
    type: "LOGIN_WITH_JWT",
    payload: decoded
  };
};

export const loginWithJWT = userData => dispatch => {
  return new Promise((resolve, reject) => {
  
    axios
      .post("/v1/api/users/login",userData)
      .then(response => {
        var loggedInUser
        if (response.data) {
          loggedInUser = response.data.data
         
          
        /*   dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" }
          }) */
        var userDetails=  setLocalStorageDetailsAtRegisterTime(loggedInUser);
        const userData = JSON.parse(userDetails);
        dispatch(setCurrentUser(userData));
      
           if(loggedInUser.userType == "banker"){
            history.push("/banker-dashboard")
            window.location.reload(false);
          } 
           if(loggedInUser.userType == "buyer"){
           
            history.push("/registration-form")
            window.location.reload(false);
          }  
          if(loggedInUser.userType == "admin"){
           
            history.push("/admin-dashboard")
            window.location.reload(false);
          } 

         // history.push("/board")
       //  history.push("/dashboard")
          resolve(response)
        }
      })
      .catch(err =>{
        
        reject(err.response.data)
      } )
    })
}
export const loginWithGoogle = userData => dispatch => {
  return new Promise((resolve, reject) => {
  
    axios
      .post("/v1/api/users/google-login",userData)
      .then(response => {
        var loggedInUser
        if (response.data) {
          loggedInUser = response.data.data
         
          
        /*   dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" }
          }) */
        var userDetails=  setLocalStorageDetailsAtRegisterTime(loggedInUser);
        const userData = JSON.parse(userDetails);
        dispatch(setCurrentUser(userData));
      
           if(loggedInUser.userType == "banker"){
            history.push("/banker-dashboard")
            window.location.reload(false);
          } 
           if(loggedInUser.userType == "buyer"){
           
            history.push("/registration-form")
            window.location.reload(false);
          }  

         // history.push("/board")
       //  history.push("/dashboard")
          resolve(response)
        }
      })
      .catch(err =>{
        
        reject(err.response.data)
      } )
    })
}

export const loginWithFb = userData => dispatch => {
  return new Promise((resolve, reject) => {
  
    axios
      .post("/v1/api/users/fb-login",userData)
      .then(response => {
        var loggedInUser
        if (response.data) {
          loggedInUser = response.data.data
         
          
        /*   dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" }
          }) */
        var userDetails=  setLocalStorageDetailsAtRegisterTime(loggedInUser);
        const userData = JSON.parse(userDetails);
        dispatch(setCurrentUser(userData));
      
           if(loggedInUser.userType == "banker"){
            history.push("/banker-dashboard")
            window.location.reload(false);
          } 
           if(loggedInUser.userType == "buyer"){
           
            history.push("/registration-form")
            window.location.reload(false);
          }  
          if(loggedInUser.userType == "admin"){
           
            history.push("/admin-dashboard")
            window.location.reload(false);
          } 

         // history.push("/board")
       //  history.push("/dashboard")
          resolve(response)
        }
      })
      .catch(err =>{
        
        reject(err.response.data)
      } )
    })
}

export const getRegistrationDetail = token => dispatch => {
  return new Promise((resolve, reject) => {
    
     axios
     .get("/v1/api/buyer/"+ token +"/personal-data")
      .then(response => {
       resolve(response.data.data)
      })
      .catch(err =>{
        
        reject(err.response.data)
      } )
    })
}
export const logoutWithJWT = () => {
  localStorage.setItem("UserDetails", "");
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    history.push("/")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}


export const forgotPassword = userData => dispatch => {
  return new Promise((resolve, reject) => {
    axios
    .post("/v1/api/users/forgot-password", userData)
    .then(res => {
      console.log("forgot password", res);
      resolve(res);
    })
    .catch(err => {
      reject(err);
    });
});
   
}
export const resetPassword = (userData) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post("/v1/api/users/reset-password/" + userData.token, userData)
      .then(res => {
        console.log("reset password", res);
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};