import React, { useState ,useEffect} from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
 
} from "reactstrap"
import { Bell,AlertTriangle ,PlusSquare,DownloadCloud,CheckCircle,File} from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
//import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import { Console } from "winston/lib/winston/transports"
import { toast, ToastContainer } from "react-toastify"
import { auth } from "firebase"
import Select from "react-select"
import { FormattedMessage } from "react-intl"
import userImg from "../../../assets/img/portrait/small/images.jpeg"
/* const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)

} */
//const auth = JSON.parse(localStorage.getItem("UserDetails"))

const nationalityOptions = [
 
  {
    value: "English",
    label: "English"
  },
  {
    value:"German",
    label: "German"
  }  
] 
const UserDropdown = props => {
  var auth = JSON.parse(localStorage.getItem("UserDetails"))
  const [modal,setModel]=useState(false)
  const [profileImage,setProfileImage] = useState(undefined);
  const [selectProfileImageFiles, setProfileImageFiles] = useState(undefined);

  const [formData,setFormData]=useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',

    
  })

  useEffect(() => {

    var token= auth.token

    axios
    .get("/v1/api/users/"+ token +"/profile")
      .then(function(response) {
        setFormData(response.data.data)
        


      }).catch(function(error) {
        console.log("error from server",error);
      })
  }, []);
  
  const toggleModal = () => {
    if(modal == true){
      setModel(false)
    }else{
      setModel(true)
    }
  }
 
  const  handleInputData = (evt)=> {
    const {name,value } = evt.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
  })); 

}
const selectApplicantIdentityCardFile = (event) => {
  setModel(true)
  setProfileImageFiles(event.target.files);
  setProfileImage(event.target.name)

};

const uploadFiles = (  currentFile,selectName)=> { 
  return new Promise((resolve, reject) => {
const files = new FormData();
files.append( 'image', currentFile, currentFile.name );
      axios.post('/v1/api/users/upload-file',files,{
        params: {
          token: auth.token,
          selectName: selectName
        
        }
      })
        
     
      .then(response => {
        if(response &&  response.data &&  response.data.data){
          var userDetails = response.data.data
          var userData = {};
          userData.token = auth.token
          userData.user_id = auth.user_id;
          userData.expires_in = auth.expires_in
          userData.userType = auth.userType;
          userData.userName = auth.userName;
          userData.phone = auth.phone;
          userData.loginWithJWT ="jwt";
          userData.language = userDetails.language
          userData.profileImage = userDetails.profileImage ?  userDetails.profileImage : ""
        
          var data = JSON.stringify(userData);
          localStorage.setItem("UserDetails", data);
         // return data; 
        };
toast.success("Successfully Upoaded");

setProfileImageFiles(undefined)


//fetchdata()

      })
      .catch(err => {
        toast.error("Upoaded failed");
        reject(err.response.data)
      })  
  
  })
} 

const upload = (e) => {
  if( selectProfileImageFiles != undefined){
    let currentFile = selectProfileImageFiles[0];
    let selectName = e.target.name
    var fileData = uploadFiles(currentFile,selectName)
          } 


};
  return (
    <DropdownMenu right>
   
     {/*  <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
            const provider = props.loggedInWith
            if (auth.token !== null) {
              if (auth.loginWithJWT === "jwt") {
                return props.logoutWithJWT()
              }
            
            } else {
              history.push("/login")
            }
         

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem> */}
      <DropdownItem style={{width:"100%"}}
      
      /*   tag="a"
        href="/login" */
        onClick={toggleModal}
      >
           <span className="align-middle" ><FormattedMessage id={"Profile"}/></span>
           <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleModal}>
         {/*  Profile */}
        </ModalHeader>
        <ModalBody>
        <FormGroup>
            
            
            <img
                src={auth.profileImage ? auth.profileImage : userImg}
                className="round"
                height="300"
                width="470"
                alt="avatar"
              
              />
          </FormGroup>
<FormGroup>
            
            <Label for="firstName"><FormattedMessage id={"First name"}/></Label>
            <Input
              type="text"
              id="email"
              value= {formData.firstName}
              placeholder="Name"
              //onChange={handleInputData} 
          
            />
          </FormGroup>
        
          <FormGroup>
            <Label for="email"><FormattedMessage id={"Last name"}/></Label>
            <Input
              type="email"
              id="email"
              value= {formData.lastName}
              //onChange={handleInputData} 
        
            />
          </FormGroup>
          <FormGroup>
            <Label for="email"><FormattedMessage id={"E-mail"}/></Label>
            <Input
              type="email"
              id="email"
              value= {formData.email}
              onChange={handleInputData} 
        
            />
          </FormGroup>
          <FormGroup>




          <div class="input-group"  style={{marginTop:"10px"}}>
  <div class="custom-file"  style={{marginLeft:"0px",width:"100%"}}>
  <input type="file" class="custom-file-input" id="inputGroupFile01"
      aria-describedby="profileImage" onChange={selectApplicantIdentityCardFile}  />
    <label class="custom-file-label"  name="profileImage"  style={{width:"465px"}} data-browse={auth.language == 'English'? "Choose file" : "Bestand kiezen" } for="profileImage">{selectProfileImageFiles != undefined? selectProfileImageFiles[0].name:'' }</label>
  </div>

  <div class="input-group-prepend" style={{marginTop:"15px"}} >
    <button  name="profileImage"
       className="btn btn-success"
       disabled={profileImage===undefined}
       onClick={upload}> <FormattedMessage id={"Upload"}/> </button>
  </div>
</div>

    {/*       <div class="custom-file">
    <input type="file" class="custom-file-input" id="inputGroupFile01" style={{width:"150px"}}
      aria-describedby="profileImage" onChange={selectApplicantIdentityCardFile}  />
    <label class="custom-file-label"  for="profileImage">{selectProfileImageFiles != undefined? selectProfileImageFiles[0].name:'bbbbbbbbbb' }</label>
    
  </div> */}


{/* 
        <div style={{marginTop:"0px"}}>

<label className="label">

<input type="file"  name="profileImage" onChange={selectApplicantIdentityCardFile} style={{marginLeft:"10px",width:"250px"}}/>
</label> 

<button style={{marginLeft:"80px"}} name="profileImage"
className="btn btn-success"
disabled={profileImage===undefined}
onClick={upload}
>
Upload 
</button>
</div>  */}
      
        
     
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            close
          </Button>{" "}
        </ModalFooter>
      </Modal>
   
     
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
            const provider = props.loggedInWith
            if (auth.token !== null) {
              if (auth.loginWithJWT === "jwt") {
                return props.logoutWithJWT()
              }
            
            } else {
              history.push("/login")
            }
         

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>

    
  )
}


//const authww = JSON.parse(localStorage.getItem("UserDetails"))
class NavbarUser extends React.PureComponent {
  state = {
    langDropdown: false,
    modal: false,
  }

handleClick(e) {
  
  this.setState(prevState => ({
    modal: true
  }));
 
  }

/*   handleLangDropdown = () =>
  this.setState({ langDropdown: !this.state.langDropdown }) */

  render() {
 const auth= JSON.parse(localStorage.getItem("UserDetails"))


    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">


        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status"><FormattedMessage id={"Available"}/></span>
            </div>
            <span data-tour="user">
              <img
                src={auth.profileImage  ? auth.profileImage : this.props.userImg }
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
       
        </UncontrolledDropdown>
        
      </ul>
      
    )
    
  }
  
}
export default NavbarUser
