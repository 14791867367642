import React from "react"
import { IntlProvider } from "react-intl"

import messages_en from "../../assets/data/locales/en.json"
 import messages_de from "../../assets/data/locales/de.json"
import messages_fr from "../../assets/data/locales/fr.json"
import messages_pt from "../../assets/data/locales/pt.json" 

const menu_messages = {
  en: messages_en,
  de: messages_de,
  fr: messages_fr,
  pt: messages_pt 
}

const Context = React.createContext()
var selectLanguage
if (localStorage.getItem("UserDetails")){
var auth= JSON.parse(localStorage.getItem("UserDetails"))
if( auth && auth.language== "English"){
  
  selectLanguage = "en"
}
if( auth && auth.language== "German"){
  selectLanguage = "de"
}
}
class IntlProviderWrapper extends React.Component {
 
  state = {
    locale: selectLanguage != undefined ? selectLanguage : "en",
    messages: selectLanguage != undefined ? menu_messages[selectLanguage] :menu_messages["en"]
  }
 
  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    var auth1= localStorage.getItem("UserDetails")
    if(this.state.locale == "en"){
      localStorage.setItem("language","English")
    }
    if(this.state.locale == "de"){
      localStorage.setItem("language","German")
    }
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale:language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
